import React, { useEffect, useRef, useState } from "react";
import ImageUploader from "./ImageUploader";
import { updatePatient } from "../../apis/patients";
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR } from "../../constants/Constants";
import { TEST_CONTAINER } from "../../constants/ContainerConstants";
import {
  PROFILE_PICTURE_UPLOADED_SUCCESS,
  PROFILE_PICTURE_UPLOADED_FAILED,
} from "../../constants/SnackbarMessages";
import { activateSnackbar } from "../../redux/slice/snackbarSlice";
import { useDispatch } from "react-redux";
import { useFileUpload } from "../../hooks/useFileUpload";
import { useDownloadFile } from "../../hooks/useDownloadFile";
import ImageCropper from "./ImageCropper";
import tempImg from "../../assets/images/dummy_400x400_ffffff_cccccc.png";
interface ImageClickUploaderProps {
  patient: PatientInformation;
}
const ImageClickUploader: React.FC<ImageClickUploaderProps> = ({ patient }) => {
  const dispatch = useDispatch();
  const [, handleFileChange] = useFileUpload();
  const [imageUrl, setImageUrl] = useState<string>();
  const { getFileSrc } = useDownloadFile();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>();
  const [openCropper, setOpenCropper] = useState<boolean>();
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = async (newImage: File) => {
    if (newImage && TEST_CONTAINER) {
      const [response, error] = await handleFileChange(
        newImage,
        TEST_CONTAINER,
        patient.publicId
      );
      if (response) {
        // Handle successful response
        let updateObj = {
          publicId: patient.publicId,
          profilePictureUrl: response,
        };
        const [res, err] = await updatePatient(updateObj);
        console.log(res);
        if (res) {
          dispatch(
            activateSnackbar({
              active: true,
              message: PROFILE_PICTURE_UPLOADED_SUCCESS,
              variant: SNACKBAR_SUCCESS,
            })
          );
        } else {
          dispatch(
            activateSnackbar({
              active: true,
              message: err || PROFILE_PICTURE_UPLOADED_FAILED,
              variant: SNACKBAR_ERROR,
            })
          );
        }
      } else {
        dispatch(
          activateSnackbar({
            active: true,
            message: PROFILE_PICTURE_UPLOADED_FAILED,
            variant: SNACKBAR_ERROR,
          })
        );
      }
    }
  };

  const handleFileAttched = (e: any) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setOpenCropper(true);
    }
  };
  const getProfileImage = async () => {
    if (patient.profilePictureUrl) {
      const url = patient.profilePictureUrl;
      const [src] = await getFileSrc(url);
      if (src) {
        setImageUrl(src);
      }
    }
  };
  useEffect(() => {
    getProfileImage();
  }, []);
  return (
    <>
      {/* {patient.profilePictureUrl && ( */}
      {true && (
        <>
          {" "}
          {/* temp removed */}
          {/* <input
            accept="image/*"
            style={{ display: "none" }}
            ref={fileInputRef}
            type="file"
            onChange={handleFileAttched}
          /> */}
          <img
            src={tempImg}
            alt="Captured"
            width="90%"
            className="p-2"
            onClick={handleClick}
          />
        </>
      )}
      {openCropper && (
        <ImageCropper
          image={file}
          setFile={setFile}
          open={openCropper}
          setOpen={setOpenCropper}
          handleFileUpload={handleFileUpload}
          // onImageCrop={function (croppedImage: string): void {
          //   throw new Error("Function not implemented.");
          // }}
          // onCancel={function (): void {
          //   throw new Error("Function not implemented.");
          // }}
        />
      )}
      {/* {!patient.profilePictureUrl && (
        <ImageUploader patientId={patient.publicId} />
      )} */}
    </>
  );
};

export default ImageClickUploader;
