import { useState } from "react";
import { CONTAINER_NAME, FILE, FILE_NAME } from "../constants/Constants";
import { uploadFile } from "../apis/files.api";
import { useSelector } from "react-redux";

export const useFileUpload = (): [
  string,
  (
    file: File,
    containerName: string,
    patientId: string,
    fileNameFromParent?: string
  ) => Promise<[string | null, Error | null]>
] => {
  const [fileName, setFileName] = useState<string>("");
  const clientObject: any = useSelector<SwivoClient>((state) => state.client);
  const handleFileChange = async (
    file: File,
    containerName: string,
    patientId: string,
    fileNameFromParent?: string
  ): Promise<[string | null, Error | null]> => {
    const pathName =
      `${clientObject.client.clientId}-${containerName}-${patientId}`.toLowerCase();
    const formData = new FormData();
    formData.append(FILE, file);
    formData.append(
      FILE_NAME,
      fileNameFromParent
        ? fileNameFromParent.replaceAll(" ", "_")
        : file.name.replaceAll(" ", "_")
    );
    formData.append(CONTAINER_NAME, pathName);
    console.log(file);
    try {
      const [response, error] = await uploadFile(formData);
      if (response) {
        return [response, null];
      } else {
        return [null, error];
      }
    } catch (error) {
      const customError = new Error(
        "An error occurred while uploading the file."
      );
      return [null, customError];
    }
  };

  return [fileName, handleFileChange];
};
